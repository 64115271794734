import useSettingForm from '../form/useSettingForm';
import { useStudentContext } from '../../../context/MyStudentContextProvider';
import { useDomain } from '../../../../../states/AppDomainProvider';
import ShowScores from '../../../../settings/interaction/ShowScores';

export default function ShowScoresSettings() {
  const { updateProfileSettingsOnChange } = useSettingForm('Show Student Scores');
  const { studentsDomain } = useDomain();
  const { currentSelectedStudentId } = studentsDomain.domainData;
  const { myStudentDomain } = useStudentContext();
  const { selectedStudentProfile } = myStudentDomain.domainData;

  const onChange = (newValue) => {
    updateProfileSettingsOnChange(currentSelectedStudentId, {
      scores: newValue,
    });
  };

  return (
    <ShowScores
      profile={selectedStudentProfile}
      onChangeCallback={(newValue) => onChange(newValue)}
    />
  );
}
