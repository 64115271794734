import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ToggleForm from '../../../components/toggle/ToggleForm';

const ShowScores = ({ profile, onChangeCallback }) => {
  const [toggle, setToggle] = useState(profile.scores);
  const showScores = profile.scores;
  // When mounting and changing a profile.
  useEffect(() => {
    setToggle(showScores);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.id]);

  const handleShowScoreOnchange = (field, newValue) => {
    if (newValue === null) return;
    setToggle(newValue);
    onChangeCallback(newValue);
  };

  return (
    <ToggleForm value={toggle} handleOnChange={handleShowScoreOnchange} />
  );
};

ShowScores.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  profile: PropTypes.object.isRequired,
  onChangeCallback: PropTypes.func.isRequired,
};

export default ShowScores;
