import { useState, useMemo, useEffect } from 'react';
import { Stack } from '@mui/material';
import { useLessonPlay } from '../../context/LessonPlayContextProvider';
import {
  ACTIVITY_TYPE_BUILD_A_BOOK_SINGLE_DATA,
  ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA,
} from '../../../../AppConstants';
import { isStudent, isTeacher } from '../../../../utils/User';
import { hideAccessibeChatIcon, showAccessibeChatIcon } from '../../../../services/AccessibeService';
import { MATCHING_ACTIVITY_ONE_CLICK } from '../../../../constants/SettingConstant';
import LessonActivityNavigator from '../lesson-bars/LessonActivityNavigator';
import { useDomain } from '../../../../states/AppDomainProvider';
import CorrectSubmissionMessage from '../ui/CorrectSubmissionMessage';
import { CELEBRATION_STYLE_KEY } from '../../../../constants/PlayPreferenceConstant';

const ALWAYS_ENABLE_NEXT_BUTTONS = [
  ACTIVITY_TYPE_BUILD_A_BOOK_SINGLE_DATA,
  ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA,
];

export const useHandleFfrPage = () => {
  const { lessonPlayDomain } = useLessonPlay();
  const { userDomain } = useDomain();
  const { lesson: { lessonUuid } } = lessonPlayDomain.domainData;
  const updateStudentUserProfile = async () => {
    const { user: userLogin, userProfile: userProfileLogin } = userDomain.domainData;
    if (isStudent(userLogin)) {
      const isFeedbackLoopOrSinglePlay = userProfileLogin.subjects.findIndex((s) => {
        const result = s.lessons.findIndex((l) => l.feedbackLoopValue || l.singlePlay || l.preAssessment);
        return result >= 0;
      }) >= 0;

      const updatedUserProfile = { ...userProfileLogin };
      updatedUserProfile.expiredSubjects.forEach((s) => {
        const playedLesson = s.lessons.find((l) => l.uuid === lessonUuid);
        if (playedLesson) {
          playedLesson.played = true;
        }
      });
      await userDomain.updateUserProfile(updatedUserProfile);

      if (isFeedbackLoopOrSinglePlay) {
        updatedUserProfile.subjects.forEach((s) => {
          const playedLesson = s.lessons.find((l) => l.uuid === lessonUuid);
          if (playedLesson) {
            playedLesson.played = true;
          }
        });
        await userDomain.updateUserProfile(updatedUserProfile);
      }
    }
  };

  return {
    updateStudentUserProfile,
  };
};

export const useControlAccessibe = () => {
  useEffect(() => {
    hideAccessibeChatIcon();
    return () => {
      showAccessibeChatIcon();
    };
  }, []);
};

export const useLessonActivityNavigator = ({ lessonPageContainerRef, handleExit, finishedActivityIndexes, isLessonCompleteRef, showBottomLessonNavigator, handleLessonEnd }) => {
  const { lessonPlayDomain } = useLessonPlay();
  const [showCelebration, setShowCelebration] = useState(false);
  const [showLessonCompletePage, setShowLessonCompletePage] = useState(false);
  const [showScores, setShowScores] = useState(false);
  const { lessonPagesData, currentPageIndex, isPreviewMode, userProfile, isPlayable, freeformAnswerData } = lessonPlayDomain.domainData;
  const [showCorrectSubmissionMessage, setShowCorrectSubmissionMessage] = useState(false);

  const onNext = () => {
    setShowCorrectSubmissionMessage(false);
    const { isMediaPlaying } = lessonPlayDomain.domainData;

    if (isMediaPlaying) {
      return;
    }
    if (currentPageIndex < lessonPagesData.length - 1) {
      lessonPageContainerRef.current.next();
    } else if (userProfile.celebrationStyle === CELEBRATION_STYLE_KEY.interactive && userProfile.celebrations && userProfile.celebrations.length > 0) {
      // eslint-disable-next-line no-param-reassign
      isLessonCompleteRef.current = true;
      handleLessonEnd();
      setShowCelebration(true);
    } else if (userProfile.celebrationStyle === CELEBRATION_STYLE_KEY.simple) {
      // eslint-disable-next-line no-param-reassign
      isLessonCompleteRef.current = true;
      handleLessonEnd();
      setShowLessonCompletePage(true);
    } else {
      // eslint-disable-next-line no-param-reassign
      isLessonCompleteRef.current = true;
      handleLessonEnd();
      if (userProfile.scores) {
        setShowScores(true);
        return;
      }
      handleExit();
    }
  };

  const onPrevious = () => {
    setShowCorrectSubmissionMessage(false);
    const { isMediaPlaying } = lessonPlayDomain.domainData;
    if (isMediaPlaying) {
      return;
    }
    lessonPageContainerRef.current.previous();
  };

  let hidePrevious = true;

  if (showCelebration || showLessonCompletePage || showScores) {
    hidePrevious = true;
  } else if (currentPageIndex === 0) {
    hidePrevious = true;
  } else if (currentPageIndex > 0) {
    if (freeformAnswerData && freeformAnswerData.length > 0) {
      const hasComment = freeformAnswerData.findIndex((ffad) => ffad.attributes.pageNumber === currentPageIndex + 1) >= 0;
      if (hasComment && finishedActivityIndexes.current.includes(currentPageIndex)) {
        hidePrevious = false;
      }
    } else {
      hidePrevious = false;
    }
  }

  let hideNext = true;
  const isAutoPlay = MATCHING_ACTIVITY_ONE_CLICK === userProfile.interactionType && userProfile.tts.enabled;
  if (showCelebration || showLessonCompletePage || showScores || (!isPlayable && currentPageIndex === lessonPagesData.length - 1)) {
    hideNext = true;
  } else if (
    currentPageIndex < lessonPagesData.length
    && (
      isPreviewMode
      || !isPlayable
      || finishedActivityIndexes.current.includes(currentPageIndex)
      || (!isAutoPlay && ALWAYS_ENABLE_NEXT_BUTTONS.includes(lessonPagesData[currentPageIndex].activityType))
      || (ALWAYS_ENABLE_NEXT_BUTTONS.includes(lessonPagesData[currentPageIndex].activityType) && !lessonPlayDomain.domainData.isMediaPlaying)
    )) {
    if (freeformAnswerData && freeformAnswerData.length > 0) {
      const hasComment = freeformAnswerData.findIndex((ffad) => ffad.attributes.pageNumber === currentPageIndex + 1) >= 0;
      if (hasComment && finishedActivityIndexes.current.includes(currentPageIndex)) {
        hideNext = false;
      }
    } else {
      hideNext = false;
    }
  } else if (showCorrectSubmissionMessage && finishedActivityIndexes.current.includes(currentPageIndex)) {
    hideNext = false;
  }

  const lessonNavigator = showBottomLessonNavigator ? (
    <LessonActivityNavigator
      onSelect={(index) => {
        lessonPageContainerRef.current.getSliderRef().slickGoTo(index);
      }}
    />
  ) : null;

  const correctSubmissionMessage = !showBottomLessonNavigator && showCorrectSubmissionMessage ? (
    <Stack marginLeft='auto' marginRight='30px'>
      <CorrectSubmissionMessage />
    </Stack>
  ) : null;

  return {
    onNext,
    onPrevious,
    hideNext,
    hidePrevious,
    showCelebration,
    children: lessonNavigator || correctSubmissionMessage,
    showLessonCompletePage,
    showCorrectSubmissionMessage,
    showScores,
    setShowCorrectSubmissionMessage,
    setShowLessonCompletePage,
    setShowCelebration,
    setShowScores,
  };
};

export const useShowScoresResult = () => {
  const [showScores, setShowScores] = useState(false);
  return {
    showScores,
    setShowScores,
  };
};

export const useTopLessonActivityNavigator = () => {
  const { lessonPlayDomain } = useLessonPlay();
  const { user, userProfile } = lessonPlayDomain.domainData;

  const showExitButton = isTeacher(user) || userProfile.exitLesson;
  return {
    showExitButton,
  };
};

export const usePrepareReinforcer = () => {
  const { lessonPlayDomain } = useLessonPlay();
  const { userProfile, terminologies } = lessonPlayDomain.domainData;

  const { reinforcers: terminologiesReinforcers } = terminologies;
  const { reinforcers: userProfileReinforcers } = userProfile;

  if (userProfileReinforcers && userProfileReinforcers.length > 0) {
    return userProfileReinforcers.map((userProfileReinforcerCode) => terminologiesReinforcers.find((t) => t.code === userProfileReinforcerCode)).filter((r) => Boolean(r));
  }
  return [];
};

export const useGetRandomCelebration = () => {
  const { lessonPlayDomain } = useLessonPlay();
  const { userProfile, terminologies } = lessonPlayDomain.domainData;
  const userCelebration = useMemo(() => {
    const { celebrations: userProfileCelebrations, celebrationStyle } = userProfile;

    if (celebrationStyle === CELEBRATION_STYLE_KEY.interactive && userProfileCelebrations && userProfileCelebrations.length > 0) {
      const { celebrations: terminologiesCelebrations } = terminologies;
      const userCelebrations = userProfileCelebrations.map((userProfileCelebrationIndex) => terminologiesCelebrations[userProfileCelebrationIndex - 1]);
      const celebrationData = userCelebrations[Math.floor(Math.random() * userCelebrations.length)];
      return {
        celebrationData,
      };
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return userCelebration;
};
