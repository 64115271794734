import MaxChoicesSettings from './MaxChoices';
import MatchingTypeSettings from './MatchingType';
import RedXPromptingSettings from './RedXPrompting';
import TooltipWithIndicator from '../../../../../components/tooltip/TooltipWithIndicator';
import ExitLessonSettings from './ExitLessonSettings';
import { redXPromptingTooltipText, matchingTooltipText, maxMatchingChoicesTooltipText, guideExitLesson, showStudentScoreTooltipText } from '../../../../../texts/TooltipText';
import InteractionContainer from '../../../../settings/InteractionContainer';
import ShowScoresSettings from './ShowScores';

const InteractionSettings = () => {
  const components = [
    {
      title: 'Red X Prompting',
      component: <RedXPromptingSettings />,
      tooltip: <TooltipWithIndicator className='tooltip switch-tooltip' {...redXPromptingTooltipText} />,
      className: 'limit-width-20',
    },
    {
      title: 'Matching Type',
      component: <MatchingTypeSettings />,
      tooltip: <TooltipWithIndicator className='tooltip switch-tooltip' {...matchingTooltipText} />,
      className: 'limit-width-30',
    },
    {
      title: 'Show Student Scores',
      component: <ShowScoresSettings />,
      tooltip: <TooltipWithIndicator className='tooltip switch-tooltip' {...showStudentScoreTooltipText} />,
      className: 'limit-width-30',
    },
    {
      title: 'Exit Activity',
      component: <ExitLessonSettings />,
      tooltip: <TooltipWithIndicator className='tooltip switch-tooltip' {...guideExitLesson} />,
      className: 'limit-width-20',
    },
    {
      title: 'Max Choices',
      component: <MaxChoicesSettings />,
      tooltip: <TooltipWithIndicator className='tooltip switch-tooltip' {...maxMatchingChoicesTooltipText} />,
      className: 'limit-width-70',
    },
  ];

  return (
    <InteractionContainer
      components={components}
      className='mystudent-settings-interaction'
    />
  );
};
export default InteractionSettings;
