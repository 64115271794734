import PropTypes from 'prop-types';

import './ShowScoresPage.scss';
import { Stack, Button, Typography } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useGetRemoteServiceWrapper } from '../../../hooks/RemoteServiceHooks';
import LoadingSpinner from '../../../../components/loaders/LoadingSpinner';
import { getUserScores } from '../../../../services/LessonPlayService';
import ObjectUtils from '../../../../utils/ObjectUtils';

const ShowScoresPage = forwardRef(({ open, onOpen, onClose, lessonPlayId }, ref) => {
  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();
  const [userScores, setUserScores] = useState();
  const buttonRef = useRef();

  useEffect(() => {
    if (lessonPlayId) {
      callRemoteServiceWrapper(async () => {
        const result = await getUserScores(lessonPlayId);
        if (!result.shouldDisplayScore) {
          onClose();
          return;
        }
        setUserScores(result);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonPlayId]);

  useEffect(() => {
    if (userScores) {
      onOpen();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userScores]);

  useImperativeHandle(ref, () => ({
    getEnableElements: async () => {
      await ObjectUtils.delay(50);
      if (buttonRef && buttonRef.current) {
        return [buttonRef.current];
      }
      return [];
    },
  }));

  if (!open || !lessonPlayId) {
    return null;
  }

  if (loading) {
    return (
      <div className='show-scores-page loading-container' data-test='loading-container'>
        <LoadingSpinner message='Loading Scores' />
      </div>
    );
  }

  return (
    <div className='show-scores-page'>
      {userScores && (
        <Stack width={250} mb={4} alignItems='center' gap={2} className='message'>
          <Typography className='title' fontSize={20} fontWeight={500}>Activity Complete!</Typography>
          <Typography textAlign='center' fontSize={16} fontWeight={500}>You answered {userScores.correct} out of {userScores.total} questions correctly.</Typography>
          <Typography fontSize={16} fontWeight={500} mt={1}>({userScores.score}%)</Typography>
        </Stack>
      )}

      <Button className='btn-blue-primary close-button' onClick={onClose} ref={buttonRef}>
        Close
      </Button>
    </div>
  );
});

ShowScoresPage.defaultProps = {
  onClose: () => { },
  open: false,
  lessonPlayId: 0,
  onOpen: () => { },
};

ShowScoresPage.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  lessonPlayId: PropTypes.number,
  onOpen: PropTypes.func,
};

export default ShowScoresPage;
