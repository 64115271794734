import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { Box, Button } from '@mui/material';
import { Howl } from 'howler';
import PropTypes from 'prop-types';
import trophyAudio from '../../../../static/sounds/trophy.mp3';
import './LessonCompletePage.scss';
import SimpleCelebration from '../../../settings/celebrations/simple-celebration/SimpleCelebration';
import ObjectUtils from '../../../../utils/ObjectUtils';

const LessonCompletePage = forwardRef(({ displayCloseButton, onClose, open, onOpen, enableSound }, ref) => {
  const soundRef = useRef();
  const buttonRef = useRef();
  useEffect(() => {
    if (enableSound) {
      // eslint-disable-next-line no-unused-vars
      soundRef.current = new Howl({
        src: trophyAudio,
        preload: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (soundRef.current && open) {
      soundRef.current.play();
      if (onOpen) {
        onOpen();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useImperativeHandle(ref, () => ({
    getEnableElements: async () => {
      await ObjectUtils.delay(50);
      if (buttonRef && buttonRef.current) {
        return [buttonRef.current];
      }
      return [];
    },
  }));

  if (!open) {
    return null;
  }
  return (
    <div className='lesson-complete-page'>
      <Box width={320} mb={4}>
        <SimpleCelebration />
      </Box>

      {displayCloseButton && (
        <Button
          className='btn-blue-primary close-button'
          onClick={onClose}
          ref={buttonRef}
        >
          Close
        </Button>
      )}
    </div>
  );
});

LessonCompletePage.defaultProps = {
  displayCloseButton: true,
  onClose: () => { },
  onOpen: () => { },
  open: false,
  enableSound: false,
};

LessonCompletePage.propTypes = {
  displayCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  enableSound: PropTypes.bool,
};

export default LessonCompletePage;
